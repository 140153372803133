import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Link,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import { Gauge } from "@mui/x-charts";
import Ellipse from "../app/components/Ellipse";

export const Orders = ({
  getOrders,
  user,
  orders,
  growthPercentage,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    getOrders({ page: 1, limit: 999999 })
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Shopify Number',
      dataIndex: 'shopifyOrderNumber',
      filters: [
        ...new Map(
          orders?.docs
            ?.filter((order) => order.shopifyOrderNumber !== null).map((order) => [
              order.shopifyOrderNumber,
              { text: order.shopifyOrderNumber, value: order.shopifyOrderNumber },
            ])
        ).values(),
      ],
      filterSearch: true,
      onFilter: (value, record) => record.shopifyOrderNumber?.startsWith(value),
    },
    {
      title: 'Tracking ID',
      dataIndex: 'trackingId',
    },
    {
      title: 'Date Ordered',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => { return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1 },
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => { return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1 },
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer',
      filters: orders?.docs?.filter((order) => order.fulfillment !== null).map((order) => {
        return {
          text: order.fulfillment?.customer,
          value: order.fulfillment?.customer
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record.customer?.startsWith(value),
    },
    {
      title: 'Order Price',
      dataIndex: 'price',
      render: (_, { price }) => `${price} EGP`,
      sorter: (a, b) => { return a.price - b.price },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Returned To Chainz",
          value: "Returned to Chainz"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => status.toUpperCase()
    },
    {
      title: 'Payment Status',
      dataIndex: 'chainzFinanceStatus',
      filters: [
        {
          text: "Pending",
          value: "Pending"
        },
        {
          text: "Paid",
          value: "Paid"
        },
      ],
      onFilter: (value, record) => record.chainzFinanceStatus === value,
      render: (_, { chainzFinanceStatus }) => chainzFinanceStatus.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { _id }) => <Space>
        <Typography sx={{ cursor: "pointer" }} onClick={() => navigate(`/orders/${_id}`)}>View Order</Typography>
      </Space>
    },
  ];

  const data = orders?.docs?.map((row) => {
    return {
      _id: row._id,
      shopifyOrderNumber: row.shopifyOrderNumber,
      chainzFinanceStatus: row.chainzFinanceStatus,
      trackingId: row.shipbluTrackingId || row.bostaTrackingId,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.preparedAt ? moment(row.preparedAt).format("lll") : null,
      dateDelivered: row.deliveredAt ? moment(row.deliveredAt).format("lll") : null,
      status: row.fulfillment ? row.status : row.status === "Cancelled" ? "Cancelled" : "INVALID",
      customer: row.fulfillment?.customer,
      price: row.fulfillment?.price,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1 }}>
          <Grid item xs={3}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Total Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {/* <Gauge width={200} height={200} sx={{fontSize: 50}} value={orders?.total} valueMax={orders?.total} /> */}
                  <Ellipse text={orders?.total} />
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{growthPercentage > 0 ? `+${growthPercentage}` : growthPercentage}% since last month</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={3}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Pending Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {/* <Gauge width={200} height={200} sx={{fontSize: 50}} value={orders?.total} valueMax={orders?.total} /> */}
                  <Ellipse text={orders?.docs.filter(order => !["Delivered", "Returned", "Returned to Chainz", "Cancelled"].includes(order.status)).length} />
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => !["Delivered", "Returned", "Returned to Chainz", "Cancelled"].includes(order.status)).length / orders?.total * 100).toFixed(1)}% of total orders are pending</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={3}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Fulfilled Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {/* <Gauge width={200} height={200} sx={{fontSize: 50}} value={orders?.total} valueMax={orders?.total} /> */}
                  <Ellipse text={orders?.docs.filter(order => ["Delivered", "Returned", "Returned to Chainz"].includes(order.status)).length} />
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => ["Delivered", "Returned", "Returned to Chainz"].includes(order.status)).length / orders?.total * 100).toFixed(1)}% of total orders are fulfilled</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={3}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Cancelled Orders</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {/* <Gauge width={200} height={200} sx={{fontSize: 50}} value={orders?.total} valueMax={orders?.total} /> */}
                  <Ellipse text={orders?.docs.filter(order => ["Cancelled"].includes(order.status)).length} />
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{(orders?.docs.filter(order => ["Cancelled"].includes(order.status)).length / orders?.total * 100).toFixed(1)}% of total orders are cancelled</Typography>
                </Box>
              </Box>
            } />
          </Grid>
        </Grid>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  orders: state?.records?.orders,
  growthPercentage: state?.records?.growthPercentage
});

const mapDispatchToProps = { getOrders };

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
