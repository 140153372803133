import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Typography,
  Box,
  Container,
  TextField,
  CssBaseline,
  Button,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  TableContainer,
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControl,
  Tabs,
  Tab,
  Card,
  CardContent,
  CardHeader,
  Divider
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect } from "react-redux";
import {
  Navigate,
  NavLink,
  Route,
  Routes,
  useNavigate,
  useParams
} from "react-router-dom";
import {
  centered_flex_box,
  MainInput,
  MainInputLabel,
  MainPassword,
  main_button,
  StyledInput,
  MainPhone,
  sec_button,
  reject_button
} from "../app/components/Styles";
import { cancelOrder, getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline, Download, MonetizationOn, Money } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";

export const Order = ({ getOrders, user, order, cancelOrder, isLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOrders({ orderId: id })
  }, [id])

  return (
    <div>
      <Box sx={{ width: "100%", padding: 5 }}>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <Typography variant='h4' sx={{ color: "var(--secColor4)" }}>{order?.isReturn ? "Return " : ""}Order for "{order?.vendor.name}"</Typography>
        </Box>
        <Box sx={{ ...centered_flex_box, mb: 1 }}>
          <NewCard sx={{ width: "100%" }} content={<Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {/* <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "New"}>Edit</Button> */}
              {!order?.isReturn && <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "Delivered"} onClick={() => navigate(`/orders/return/${id}`)}>Start Return</Button>}
              {!order?.isReturn && <Button sx={{ ...main_button, mr: 2 }} disabled={order?.status !== "Delivered" && order?.status !== "Returned to Chainz"} onClick={() => navigate(`/orders/exchange/${id}`)}>Start Exchange</Button>}
              {["New", "Sold Out", "Preparing", "Prepared"].includes(order?.status) && <Button sx={{ ...main_button, mr: 2 }} onClick={() => cancelOrder({orderId: id})}>Cancel Order</Button>}
            </Box>
            <Box>
              {/* <Button disabled={order?.status !== "New"} sx={{ ...reject_button, mr: 2 }}>Cancel</Button> */}
            </Box>
          </Box>} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <NewCard content={<>
              <Typography>Order {id}</Typography>
              {order?.shopifyOrderNumber && <Typography>Shopify: {order?.shopifyOrderNumber}</Typography>}
              <Typography>Created at {moment(order?.createdAt).format("lll")}</Typography>
              <Typography fontWeight="bold">Status: {order?.status}</Typography>
              {order?.assignedAt && <Typography>Assigned to employee at {moment(order?.assignedAt).format("lll")}</Typography>}
              {order?.preparedAt && <Typography>Packed at {moment(order?.preparedAt).format("lll")}</Typography>}
              {order?.delivered && <Typography>Delivered at {moment(order?.delivered).format("lll")}</Typography>}
            </>} />

            {order?.transactions.map((transaction) => {
              return <NewCard sx={{ mt: 2 }} content={<>
                <Typography fontWeight="bold"><MonetizationOn sx={{ color: transaction?.type === "COD" ? "green" : "red" }} />{transaction?.type} ({transaction?.service})</Typography>
                <Typography fontWeight="bold">Value: {transaction?.value} EGP</Typography>
                <Typography>Cashout ID: {transaction?.cashoutId}</Typography>
                <Typography>Created at {moment(transaction?.createdAt).format("lll")}</Typography>
              </>} />
            })}
          </Grid>
          <Grid item xs={8}>
            <NewCard content={<>
              <Typography variant="h5" textAlign="center">Shipping Details</Typography>
              <Divider />
              {order?.orderFulfillment?.customer && <Typography>Name: {order?.orderFulfillment?.customer}</Typography>}
              {order?.orderFulfillment?.phone && <Typography>Phone Number: <NavLink to={`tel:"${order?.orderFulfillment?.phone}`}>{order?.orderFulfillment?.phone}</NavLink></Typography>}
              {order?.orderFulfillment?.shippingAddress?.address1 && <Typography>Address 1: {order?.orderFulfillment?.shippingAddress?.address1}</Typography>}
              {order?.orderFulfillment?.shippingAddress?.address2 && <Typography>Address 2: {order?.orderFulfillment?.shippingAddress?.address2}</Typography>}
              {order?.orderFulfillment?.price && <Typography fontWeight={"bold"}>Total Price: {order?.orderFulfillment?.price} EGP</Typography>}
              {order?.transactions?.length > 0 && <Typography fontWeight={"bold"}>Total Received: {order?.transactions.reduce((acc, transaction) => { return transaction.type === "COD" ? acc + parseFloat(transaction.value) : acc - parseFloat(transaction.value) }, 0)} EGP</Typography>}
              <Divider />
              {order?.bostaExceptions && order.bostaExceptions.length > 0 && <>
                <Divider />
                <Typography variant="h5" textAlign="center">Shipping Issues</Typography>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography fontWeight={"bold"}>ID</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography fontWeight={"bold"}>Issue</Typography>
                    </Grid>
                    {order?.bostaExceptions.map((reason, idx) => {
                      return (
                        <>
                          <Grid item xs={2}>
                            <Typography>{idx+1}</Typography>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography>{reason.reason ? `[${moment(reason.date).format("DD-MM-YYYY HH:mm")}]: ${reason.reason}` : reason}</Typography>
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>
                </Box>
              </>}
            </>} />

            <NewCard sx={{ mt: 2 }} content={<>
              <Typography variant="h5" textAlign="center">Items</Typography>
              <Divider />
              <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography fontWeight={"bold"}>Product Name</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={"bold"}>SKU</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography fontWeight={"bold"}>Quantity Ordered</Typography>
                  </Grid>
                  {order?.orderItems && order?.orderItems.length > 0 && order?.orderItems.map((item) => {
                    return (
                      <>
                        <Grid item xs={4}>
                          <Typography sx={{...(item.isSoldOut ? {color: "var(--primaryColor) !important"} : {})}}>{item.stockItem.product.name}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{...(item.isSoldOut ? {color: "var(--primaryColor) !important"} : {})}}>{item.stockItem.product.sku || item.stockItem.product.externalId}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{...(item.isSoldOut ? {color: "var(--primaryColor) !important"} : {})}}>{item.quantityOrdered}</Typography>
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
              </Box>
            </>} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  order: state?.records?.order
});

const mapDispatchToProps = { getOrders, cancelOrder };

export default connect(mapStateToProps, mapDispatchToProps)(Order);
