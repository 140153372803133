import {
  FETCH_RECORDS,
  FETCH_RECORDS_SUCCESS,
  FETCH_RECORDS_FAIL,
  WAIT,
  WAIT_SUCCESS,
  WAIT_FAIL
} from "./types";
import {
  delRequest,
  getRequest,
  postRequest,
  putRequest
} from "../../../core/network";
import endpoints from "../../../constants/endPoints.json";
import { notification } from "antd";
import download from "downloadjs";

export const getProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.partner.products)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/products");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const editProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.partner.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteProducts = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.partner.products)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getWarehouses = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.warehouses)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getInbounds = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.inbounds)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addInbounds = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  postRequest(data, { responseType: "arraybuffer" }, undefined, token, endpoints.partner.inbounds)
    .then((response) => {
      const { data } = response;
      download(response.data, response.headers["filename"], "application/pdf")
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const getOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.partner.orders)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/ims/admin/orders");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteOrders = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.partner.orders)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getCOD = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.cod)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getTransactions = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.transactions)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getDashboard = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.dashboard)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getKeys = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.keys)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addKey = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  putRequest(data, undefined, undefined, token, endpoints.partner.keys)
    .then((response) => {
      const { data } = response;
      notification.success({
        message: data?.message
      });
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteKey = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.partner.keys)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: WAIT_SUCCESS
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};

export const getExchanges = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.exchanges)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addExchange = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.partner.exchanges)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/orders");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const addReturn = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.partner.returns)
    .then((response) => {
      const { data } = response;
      notification.success({
        message: data?.message
      });
      dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
      navigate && navigate("/orders");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const getReturns = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  getRequest(data, undefined, token, endpoints.partner.returns)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const deleteReturn = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: FETCH_RECORDS });
  const token = useState().auth?.token;

  delRequest(data, undefined, token, endpoints.partner.returns)
    .then((response) => {
      const { data } = response;
      return dispatch({
        type: FETCH_RECORDS_SUCCESS,
        payload: data
      });
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: FETCH_RECORDS_FAIL
      });
    });
};

export const cancelOrder = (data, navigate) => (dispatch, useState) => {
  dispatch({ type: WAIT });
  const token = useState().auth?.token;

  postRequest(data, undefined, undefined, token, endpoints.partner.cancelOrder)
    .then((response) => {
      const { data } = response;
      dispatch({
        type: WAIT_SUCCESS
      });
      notification.success({
        message: data?.message
      });
      navigate && navigate("/orders");
    })
    .catch((err) => {
      notification.error({
        message: err?.response?.data?.message
      });
      console.log(err);
      return dispatch({
        type: WAIT_FAIL
      });
    });
};